.navbar { padding: 38px 32px;
    .navbar-brand { width: 154px; padding: 0; }
    .navbar-nav {
        .nav-link { margin-right: 48px; padding: 0; color: $theme-dark; 
            &:last-child { margin-right: 0; }
        } 
        .dropdown .nav-link { margin: 0; }
    }
}


@media (min-width: 1200px) and (max-width: 1399px) {
    .navbar { padding: 30px 32px; 
        .navbar-nav {
            .nav-link { margin-right: 36px;}
        }
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .navbar { padding: 24px 20px; 
        .navbar-nav {
            .nav-link { margin-right: 26px;}
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar { padding: 20px 0px; 
        .navbar-nav {
            .nav-link { margin-right: 12px; }
        }
    }
}
@media (max-width: 767px) {
    .navbar { padding: 12px 16px; display: none; }

}