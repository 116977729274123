.form-group { margin-bottom: 10px; position: relative; 
	&.icon-group {
		&::before,
		&::after { @extend %after-before; width: 24px; height: 24px; top: 15px; background: no-repeat center center / 20px auto;}
		&::before { left: 18px; }
		&::after { right: 18px; }
  }
  .password-icon { position: absolute; width: 24px; height: 24px; top: 15px;  right: 24px;  background: url(../../images/icon/eye-icon.svg) no-repeat center center / 20px auto; z-index: 2; }
  .password-icon.active { background-image: url(../../images/icon/eye-close-icon.svg); }
  &.password-group::before { background-image: url(../../images/icon/lock-icon.svg); }
  &.username-group::before { background-image: url(../../images/icon/envelope-iocn.svg); }
}
.label { margin-bottom: 8px; font-size: 12px; line-height: 20px; letter-spacing: 0.2px; text-transform: uppercase; }

.form-control { padding: 15px 24px; font-size: 15px; line-height: 23px; border: 1px solid rgba($theme-dark, 0.25); border-radius: 8px; background-color: $light; border-radius: 2em;
  .icon-group &  { padding-left: 48px; }
	.password-group & { padding-right: 48px; }
	&.active { background-image: url(../../images/icon/eye-close-icon.svg); }

  
  &.password { padding-right: 36px; }
  &:focus { @include box-shadow(none); border-color: $theme-dark; }
  &.formSelect { padding-right: 40px; background-image: url(../../images/icon/dropdown-arrow.svg); background-repeat: no-repeat; background-position: right 12px center; background-size: auto 70%; }
  &.hasError { border-color: $danger; }
  &.formTextarea { resize: none; }
}

.invalidFeedback { display: block; color: $danger; }
.form-check { 
  label { font-size: 15px; line-height: 23px; }
  input { margin-top: 2px; margin-right: 8px; width: 23px; height: 23px; border-color: $border-dark; flex-shrink: 0; }
} 

.formRadio {
  input { margin-top: 0; margin-right: 8px; width: 20px; height: 20px; border-width: 2px;  border-color: $border-dark;
    &:checked[type="radio"] { background: transparent url(../../images/icon/checkbox.svg) no-repeat center center / 18px auto;  }
  }
}

.form-switch.form-check { padding-left: 0;
  .form-check-input { margin: 0; width: 44px; height: 25px; background-color: $theme-dark; background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill='%23D9D9D9'/></svg>"); background-size: 20px auto; background-position: left 3px center; border-color: $border-color;
      &:checked { background-color: #FB8A00;  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill='%23070302'/></svg>") !important; border-color: #FB8A00; border: none; background-position: right 3px center; }
  }
  label { margin-left: 4px;}
  &.switch-small {
      .form-check-input { margin: 0; width: 23px; height: 16px; background-size: 12px auto; background-position: left 2px center;
          &:checked { background-position: right 2px center; }
      }
  }
  &.theme-switch {
      .form-check-input { 
          &:checked { background-color: $theme-color; }
      }
  }
}
.form-check { margin-bottom: 0; min-height: inherit; 
  .form-check-input {
    &:hover,
    &:focus { border-color: $theme-dark;}
    &:checked { background-color: $theme-dark; border-color: $theme-dark;}
  }
}


.bigcheck { padding: 2px 0px 2px 22px;
    .form-check-input { margin-top: 0; width: 22px; height: 22px; border-width: 2px; }
    label { padding-left: 6px; }
}
::-webkit-input-placeholder     { color: rgba($theme-dark, 0.4); }
::-moz-placeholder              { color: rgba($theme-dark, 0.4); }
:-ms-input-placeholder          { color: rgba($theme-dark, 0.4); }
:-moz-placeholder               { color: rgba($theme-dark, 0.4); }
.smeet{
  border-radius: 10%;
}
// .form-check-input:checked[type="checkbox"] { background-image: url(../../images/input/check-icon.svg);}
// .form-check-input:checked[type="radio"] { background-image: url(../../images/input/switch-light.svg); background-position: 64% 50%; background-size: 74% auto;}
// .form-switch .form-check-input:checked[type="checkbox"] { background-image: url(../../images/input/switch-light.svg); }
