.anchor-locations.anchor-locations-payment {
    .auth-block { padding: 24px;
        .head { margin-bottom: 24px; }
        .left-block,
        .right-block { padding-top: 0; padding-bottom: 0; }
    }
    .loc-info { font-size: 14px; line-height: 18px; margin-bottom: 8px; }
}

.accordion-item { margin-bottom: 12px; border: none; background: rgba($light, 0.6); box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09); border-radius: 10px !important;
    .accordion-button { background: transparent; box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09); border-radius: 10px;
        &:focus { box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09); }
        &:not(.collapsed) { color: $theme-dark; background: rgba($light, 0.6);
            &::after { filter: brightness(0);}
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
   
}
@media (max-width: 767px) {
    .anchor-locations.anchor-locations-payment {
        .auth-block { padding: 28px 10px 0px; 
            .left-block { padding-top: 0; }
        }
        .item { padding-left: 0; border: none; }
        .right-block{
           .head { margin-bottom: 9px; }
            h4 { margin: 10px 0 4px; font-size: 14px; line-height: 17px; font-weight: $theme-font; }
        }

    }
}