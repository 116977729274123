.faq-section .auth-block {
    overflow: hidden;
    padding: 24px 32px;
}

.faq-style-wrapper {
    margin-top: 2em;
}
.faq-row-wrapper .faq-title {
    margin-bottom: 16px;
    border: none;
}
.faq-style-wrapper .faq-title {
    font-size: x-large;
}

.faq-style-wrapper .faq-body .faq-row {
    padding: 0.75em 0;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
    font-size: larger;
    color: grey;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
    padding: 2em !important;
    font-size: medium !important;
    color: teal !important;
}

.row-option {
    margin-top: 2em;
}

.row-option label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
}

.row-option input {
    padding: 0.5em;
    width: 2em;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.row-option button {
    padding: 0.5em 1em;
    margin-left: 1em;
    font-size: 1em;
    border-radius: 4px;
    background-color: #5000ca;
    border: 1px solid;
    color: white;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw svg {
    fill: #070302;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c:last-child {
    border: none;
}

@media (max-width: 767px) {
    .faq-section .auth-block {
        margin-top: 54px;
        background: #fff;
        border-radius: 24px;
        padding: 24px;
    }
}