.auth-section { padding: 22px 0px 60px; position: relative; 
    > div { position: relative; z-index: 2;}
}
.auth-block { margin: 0px auto; padding: 32px; width: 434px; max-width: 100%; background: $light; border-radius: 40px; @include box-shadow(0px 4px 44px rgba(0, 0, 0, 0.09));
    .head { margin-bottom: 30px;}
    .forgot-link  { margin: 16px 0px 20px; }
    .bottom-note { margin-top: 35px; font-family: $theme-font; 
        b { font-family: $theme-font-medium;}
    }
}
.loc-register-section {
    .auth-block { width: 890px; }
    .left-block { padding-right: 5%; }
}
.hours-list { padding-left: 16px;
    .form-control { padding: 0 16px 0 0; border: none; width: auto; margin: 0px auto; background-position: right center; }
    .item { margin-bottom: 13px; }
}
.request-sending {
    .title { margin-bottom: 48px; }
    .artwork { margin: 20px 0px 26px; display: block; } 
}

.request-approved {
    .center-content { margin: 60px 0px 80px; }
    .approved-icon { margin: 32px 0;}
}




@media (max-width: 991px) {
    .hours-list { padding-left: 0; }
}


@media (max-width: 767px) {

    .auth-section { padding: 30px 0px 40px;}
    .auth-block  { padding: 10px 12px 0; background: transparent; @include box-shadow(none);  
        .forgot-link  { margin: 13px 0px 16px; }
        &.custom-popup{ background-color: $light; overflow: hidden; padding: 24px 15px; text-align: center;} 
    }
    .login-section { 
        &::after { content: ""; position: absolute; width: 100%; height: 300px; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .mobile-logo { margin: 30px 0px 24px;}
    }
    .create-account-section {
        &::after { content: ""; position: absolute; width: 100%; height: 200px; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .mobile-logo img { width: 257px; }
    }
    .loc-register-section { padding: 18px 0 20px;
        &::after { content: ""; position: absolute; width: 100%; height: 225px; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .auth-block { padding: 0; 
            .head { margin-bottom: 20px;}
        }
        .left-block { padding-right: 12px; }
        h1 { margin-bottom: 20px; padding: 0 48px; font-size: 18px; line-height: 24px;}
    }
    .hours-list { margin: 16px 0 11px; }
    .request-sending { padding: 0;
        &::after { content: ""; position: absolute; width: 100%; height: 80%; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .auth-block { padding: 66px 0px 48px; min-height: 100vh; }
    }
    .request-approved { padding: 0;
        .auth-block { padding: 24px 0; min-height: 100vh; }
        &::after { content: ""; position: absolute; width: 100%; height: 80%; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .center-content { margin: 24px 0px; }
    }
    .anchor-locations { padding-top: 10px;}
}