.pwd-container {
    position: relative;
    /* width:  310; */
  }
  
  .pwd-container input {
    /* padding: 5px 30px 5px 10px; */
    /* font-size: 20px; */
  }
  
  .pwd-container img {
    cursor: pointer;
    position: absolute;
    width: 24px;
    right: 15px;
    top: 17px;
  }