.support-chat { position: fixed; z-index: 5; right: 46px; bottom: 42px;
  .dropdown-toggle { border-radius: 50%; 
    &::after { display: none;}
  }
  .dropdown-menu { top: auto !important; bottom: 100% !important; right: 0 !important; left: auto !important; @include transform(none !important); width: 388px; max-width: 100vw; padding: 0px; border-radius: 20px; overflow: hidden; }
  .chat-section {
    .head-block { padding: 10px 24px; background: $theme-color; border-radius: 20px; }
    .chat-block { padding: 16px 12px 0; max-height: calc(100vh - 300px); height: 235px; overflow: auto; }
    .chat { margin-bottom: 9px; margin-left: auto; width: auto; max-width: 90%; font-size: 17px; line-height: 24px; font-family: $theme-font-light; color: $light; padding: 6px 12px; background: $theme-dark; border-radius: 12px; position: relative;
      &:last-child { margin-bottom: 0; }
      &::after { @extend %after-before; position: absolute; right: -2px; bottom: -4px; width: 11px; height: 20px; background: url("../../images/block-chat-shape.svg") no-repeat center center / cover; }
      &.opp { margin-left: 0; margin-right: auto; background: #D6CE3D; color: $theme-dark;
        &::after { left: -2px; right: auto; background-image: url(../../images/theme-chat-shape.svg);}
      }
    }
    .bottom-block { padding: 20px 12px; position: relative;
      button { width: auto; position: absolute; top: 50%; right: 22px; @include transform(translateY(-50%)); }
    }
  }
}

@media (max-width: 767px) {
  .support-chat { 
    .dropdown-menu { position: fixed !important; bottom: 0 !important; height: 100vh; 
      &::after { content: ""; position: absolute; width: 100%; height: 90%; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
      .chat-section { position: relative; z-index: 2;}
    }
    .chat-section {
      .head-block { padding: 20px 48px 0px; background: transparent; justify-content: center !important;
        img { display: none}
        p { font-size: 18px; font-family: $theme-font-bold; }
      }
      .chat-block { max-height: calc(100vh - 140px); height: calc(100vh - 140px);   }
    }
  }
}