.back-btn { position: absolute; z-index: 6; left: 18px; top: 12px; background: transparent !important; border: none !important; border-radius: 50%; } 
.mobile-menu { 
  .menu-btn { position: absolute; z-index: 6; left: 18px; top: 12px; background: transparent !important; border: none !important; border-radius: 50%; } 
}
.offcanvas { background: $theme-dark; max-width: 66%; 
  .offcanvas-header { padding: 29px 36px; }
  .offcanvas-body { padding: 0;
    ul {
      li { margin-bottom: 10px; }
      a { padding: 11px 38px; color: $light; display: block; position: relative;
        &.active { background-color: #182125; 
          &::before { opacity: 1;}
        }
        &::before { @extend %after-before; height: 100%; width: 2px; left: 0; top: 0; background: $theme-color; opacity: 0; }
      }
    }
  }
  .menu-bootom { padding: 10px 0px 38px; }
}

@media (max-width: 767px) {
  .mobile-menu { 
    
  }
}