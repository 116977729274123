$theme-font: "Helvetica Neue", sans-serif;
$theme-font-light: "Helvetica Neue Light", sans-serif;
$theme-font-medium: "Helvetica Neue Medium", sans-serif;
$theme-font-bold: "Helvetica Neue Bold", sans-serif;
$theme-color: #EEE200;
$theme-dark: #070302;
$theme-medium: #EFF2F6;
$secondary: #323842;
$light: #fff;
$danger: #FF4800;
$warning: #ff870b;
$success: #16A750;
$success-bg: #9AD9BE;
$danger-bg: #ffcccc;
$border-color: #50555d;
$border-dark: #979797;
$border-dark2: #a7acb4;
$border-medium: #ebebf0;
$border-light: #d2d5d9;
$border-light2: #D8D8D8;
$border-input: #dedfe3;
$common-transition: all 0.3s ease-in-out;
$theme-gradient: linear-gradient(261.07deg, #a628ec 4.18%, #803be6 107.85%);
