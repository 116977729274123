.anchor-locations {
    .auth-block { width: 1072px; padding: 0px 24px;
        .head { margin-bottom: 24px; }
        .left-block,
        .right-block { padding-top: 36px; padding-bottom: 36px; }
        .left-block { width: 45.8%; padding-right: 36px; }
        .right-block { width: 54.2%; padding-left: 36px; border-left: 1px solid rgba( $theme-dark, 0.2) } 
    }
    .location-list {
        .item { padding: 10px 15px; border-left: 2px solid rgba( $theme-dark, 0.4); }
        h5 { margin-bottom: 4px; }
        p { margin-bottom: 12px; font-size: 14px; line-height: 20px; }
    }
    .report-list {
        p { margin: 0;}
        .item { border: 1px solid rgba(0, 0, 0, 0.23); box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09); border-radius: 10px;}
        .head-bar { padding: 11px 12px 8px; font-size: 12px; line-height: 18px; border-bottom: 1px solid rgba(0, 0, 0, 0.23); 
            > div:first-child { width: 45%; }
        }
        .head-bar1 { padding: 11px 12px 8px; font-size: 12px; line-height: 18px; border-bottom: 1px solid rgba(0, 0, 0, 0.23); 
            > div:first-child { width: 54%; }
        }
        .pick-drop { padding: 12px; font-size: 11px; line-height: 18px; 
            .address { width: 50%; position: relative; padding-left: 18px;
                &::before { content: ""; display: inline-block; position: absolute; left: 0; top: 5px; width: 11px; height: 11px; border-radius: 50%; }
                &.pick:before { background: $success; }
                &.drop:before { background: $danger; }
            }
            small { font-size: 8px; line-height: 10px; font-family: $theme-font; }
        }
        .bottom-bar { padding: 7px 12px 9px; font-size: 13px; line-height: 20px; border-top: 1px solid rgba(0, 0, 0, 0.23); 
            .right-icon { margin-right: 3px;}
        }
        .delivered { font-family: $theme-font-bold; } 
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .anchor-locations {
        .report-list {
            .head-bar { padding-top: 6px; padding-bottom: 0px; @include flex-direction(column);
                > div:first-child { width: 100%;  }
            }
            .head-bar1 { padding-top: 6px; padding-bottom: 0px; @include flex-direction(column);
                > div:first-child { width: 100%;  }
            }
        }
    }
}
@media (max-width: 767px) {
    .anchor-locations {
        &::after { content: ""; position: absolute; width: 100%; height: 90%; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        .auth-block { width: 1072px; padding: 0px 14px;
            .head { margin-bottom: 14px; }
            .left-block,
            .right-block { width: 100%; padding-bottom: 0; padding-top: 18px;
               
            }
            .left-block {  padding-right: 0px; padding-top: 25px; }
            .right-block { padding-left: 10px; padding-top: 6px; border-left: none; 
                .head { margin-bottom: 10px; }
            } 
        }
        .location-list {
            .item { padding: 2px 15px;
            }
        }
        .report-list {
            .item { background: rgba( $light, 0.6); border: 0}
            .head-bar { padding-top: 6px; padding-bottom: 0px; @include flex-direction(column); border: 0;
                > div:first-child { width: 100%;  }
                .ids { font-family: $theme-font-medium; font-size: 15px; line-height: 20px; }
                .date-time { font-family: $theme-font; font-size: 11px; line-height: 16px;}
            }
            .head-bar1 { padding-top: 6px; padding-bottom: 0px; @include flex-direction(column); border: 0;
                > div:first-child { width: 100%;  }
                .ids { font-family: $theme-font-medium; font-size: 15px; line-height: 20px; }
                .date-time { font-family: $theme-font; font-size: 11px; line-height: 16px;}
            }
            .pick-drop { padding-top: 4px; padding-bottom: 8px; @include flex-direction(column); position: relative;
                &::after { content: ""; position: absolute; height: 50%; border-left: 1px dashed rgba( $theme-dark, 0.2); top: 15%; left: 17px; }
                .address { width: 100%; margin-bottom: 5px; z-index: 2;
                    &:last-child { margin-bottom: 0; }
                    &::before { top: 6px; }
                    &::after { content: ""; position: absolute; left: 0; top: 5px; width: 11px; height: 11px; border-radius: 50%; }
                }
            }
            .bottom-bar { padding: 5px 12px 6px;}
        }
        .mobile-block {
            .viewall-btn { padding: 9px 17px; font-family: $theme-font-bold; margin-top: 4px; margin-bottom: 60px; background: rgba( $light, 0.4); color: $theme-dark; width: 100%; background: rgba( $light, 0.6); border: none; }
            .theme-btn { margin-top: 10px;  }
        }
    }
}