/* Style the form - display items horizontally */
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    // width:100%
  }
  
  /* Add some margins for each label */
  .form-inline label {
    margin: 5px 10px 5px 0;
  }
  
  /* Style the input fields */
  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    width:57%
  }
  
  /* Style the submit button */
  .form-inline button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
  }
  
  .form-inline button:hover {
    background-color: royalblue;
  }
  
  /* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
  @media (max-width: 800px) {
    .form-inline input {
      margin: 10px 0;
      width:100%;
      padding-bottom: 4px;
    }
  
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }