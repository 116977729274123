.business-section { padding: 40px 0; position: relative;
    > div { position: relative; z-index: 2;}
    .auth-block { width: 1000px; }
    h3 { font-family: $theme-font-bold; }
    p { font-family: $theme-font;}
    .auth-block .head { margin-bottom: 24px; }
}
.business-list {
    .item { margin-bottom: 32px; }
    .img-block { width: 100px; @include flex-shrink(0);
        img { width: 100%; border: 1px solid rgba(0, 0, 0, 0.3); box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09); border-radius: 10px; }
    }
    h5 { margin-bottom: 4px;}
    p { margin-bottom: 6px;  }
}

@media (max-width: 991px) {
}


@media (max-width: 767px) {
    .business-section { padding: 60px 0 20px;
        &::after { content: ""; position: absolute; width: 100%; height: 90vh; background: $theme-color; filter: blur(50px); top: 0; left: 0; z-index: 1;}
        p { font-size: 15px; line-height: 22px;}
    }
    .business-list {
        .img-block { width: 84px; }
        h5 { font-size: 16px; }
    }

}