.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
}
.fp-container .fp-loader {
    top: 30%;
    left: 43%;
    z-index: 1000;
    position: absolute;
} 


@media (min-width: 1200px) and (max-width: 1399px) {
    .fp-container .fp-loader {
        top: 30%;
        left: 43%;
        z-index: 1000;
        position: absolute;
    } 
}
@media (min-width: 992px) and (max-width: 1199px) {
    .fp-container .fp-loader {
        top: 30%;
        left: 39%;
        z-index: 1000;
        position: absolute;
    } 
}

@media (min-width: 768px) and (max-width: 991px) {
    .fp-container .fp-loader {
        top: 30%;
        left: 35%;
        z-index: 1000;
        position: absolute;
    } 
}

@media (max-width: 767px) {
    .fp-container .fp-loader {
        top: 30%;
        left: 23%;
        z-index: 1000;
        position: absolute;
    } 

}